<nb-card class="tag-card">
  <nb-card-body>
    <label class="label">Tag</label>
    <input type="text" nbInput [(ngModel)]="newTag.label" fullWidth />
    <br />

    <label class="label">Tag Description</label>
    <textarea
      class="description no-resize"
      nbInput
      placeholder="Help your future self remember what this tag is for."
      fullWidth
      autocomplete="new-password"
      [(ngModel)]="newTag.description"
    ></textarea>
    <br />
    <br />

    <div class="buttons-container">
      <button
        nbButton
        status="primary"
        (click)="addTag()"
        [nbSpinner]="isLoading"
        [disabled]="newTag.label.length === 0"
      >
        {{ !!newTag.id ? 'Update' : 'Add' }} Tag
      </button>
    </div>
  </nb-card-body>
</nb-card>
