import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@base-component';
import { ITag } from '@interfaces';
import { WebsocketService } from '@services';

@Component({
  selector: 'resplendent-new-tag-dialog',
  templateUrl: './new-tag-dialog.component.html',
  styleUrls: ['./new-tag-dialog.component.scss'],
})
export class NewTagDialogComponent extends BaseComponent {
  constructor(private websocketService: WebsocketService) {
    super();
  }
  @Output() tagAdded: EventEmitter<string> = new EventEmitter();
  @Input() newTag: Partial<ITag> = {
    id: null,
    label: '',
    description: '',
  };

  async addTag() {
    this.startLoading();
    const { res, success } = await this.websocketService.asyncRequest('CREATE_TAG', {
      id: this.newTag.id,
      label: this.newTag.label,
      description: this.newTag.description,
    });
    this.stopLoading();
    if (success) {
      this.tagAdded.emit(res.id);
    } else {
      this.tagAdded.emit(null);
    }
  }
}
